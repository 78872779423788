const translations = {
  homepage: {
    upperContent: {
      title: "לימודים מצוינים מתחילים במנעול חכם.",
      subtitle:
        "לוקרים מספקת לכם סביבת לימודים מתקדמת, חווייתית, בריאה ואיכותית.\nהזמינו לוקר בקלות דרך האתר או האפליקציה שלנו.",
      newText: "חדש!",
      newExplanation: "תנו לילד שלכם לבחור את הלוקר שהוא רוצה!"
    },
    middleContent: {
      title: "הכירו את המנעול החכם",
      chooseLocker: "בוחרים לוקר",
      chooseLockerDescription: "מכניסים את פרטי התלמיד/ה ובוחרים את הלוקר הכי מתאים.",
      downloadApp: "מורידים את האפליקציה",
      downloadAppDescription: "התלמיד/ה מוריד את האפליקציה ומתחבר ללוקר שלו",
      controlLocker: "שולטים בלוקר בלחיצה",
      controlLockerDescription: "כפתור פשוט פותח וסוגר את הלוקר לבקשת התלמיד/ה"
    },
    aboutLockerim: {
      right: {
        title: "חברת לוקרים",
        description:
          "חברת לוקרים מקבוצת רב בריח, מתמחה ביצירת פתרונות אחסון חכמים ומתקדמים במרחב הציבורי והפרטי. לוקרים הינה הגדולה והמובילה בענף ולה מוניטין, ידע וניסיון נצבר של למעלה מ-20 שנים בתחום. לוקרים פועלת בלמעלה מ-600 אתרים בפריסה ארצית מקרית שמונה בצפון ועד יטבתה בדרום."
      },
      cards: {
        description: "שהבינו שאיכות החיים והלמידה משתפרת כשיש לתלמיד לוקר משלו",
        lockers: "לוקרים",
        lockersNumber: "150,000",
        lockersDescription: "פרוסים ברחבי הארץ ומעניקים פתרון אחסון ללקוחות רבים",
        sites: "אתרים",
        sitesNumber: "600",
        sitesDescription: "שבחרו את פתרונות האחסון המובילים של לוקרים",
        customers: "לקוחות",
        customersNumber: "500,000",
        customersDescription: "שהבינו שאיכות החיים והלמידה משתפרת כשיש לתלמיד לוקר משלו",
        experienceNumber: "20",
        experience: "שנות ניסיון",
        experienceDescription: "שגרמו לנו להבין ולהציע את פתרון האחסון המיטבי לכל מטרה",
      }
    },
    ourCustomers: {
      title: "הלקוחות שלנו",
      subtitle:
        "אנחנו עובדים עם מיטב בתי הספר בארץ ומשתפים פעולה עם חברות מסחריות גדולות שסומכות עלינו עם דברי הערך שלהן."
    },
    bottomContent: {
      title: "אפשר גם באפליקציה",
      subtitle:
        "הורידו את האפליקציה ותוכלו להזמין ולתפעל את הלוקר שלכם בקלות ובמהירות."
    }
  },
  howItWorks: {
    title: "איך זה עובד",
    subtitle:
      "יש לנו שתי דרכים שבהם תוכלו לבחור את הלוקר המתאים ביותר לתלמיד/ה",
    bottomContent: {
      title: "אפשר גם באפליקציה",
      subtitle:
        "הורידו את האפליקציה ותוכלו לתפעל את הלוקר שלכם בקלות ובמהירות, לבצע פעולות בחשבון וגם לבצע רכישה."
    },
    moreQuestions: "יש לכם שאלות נוספות?",
    card: {
      buttons: {
        first: "אני אזמין ואבחר את הלוקר בעצמי",
        second: "אתן לתלמיד להזמין את הלוקר באפליקציה"
      },
      items: {
        firstChoice: {
          step1: {
            title: "נרשמים ובוחרים את הלוקר",
            description:
              "ממלאים פרטים ובוחרים לוקר לפי מתחם/כיתה או לפי מספר לוקר ספציפי.",
            additionalNotes: {
              question: "לא יודעים איזה לוקר לבחור?",
              answer: "תוכלו לתת לתלמיד לבחור את הלוקר באפליקציה"
            }
          },
          step2: {
            title: "משלמים ומקבלים סיכום הזמנה",
            description:
              "מזינים פרטי תשלום (אשראי). לאחר התשלום ההורה יקבל סיכום הזמנה וחשבונית למייל."
          },
          step3: {
            title: "התלמיד מקבל קישור לנייד",
            description:
              "באמצעות הקישור התלמיד יוריד את האפליקציה ויוכל לפתוח את הלוקר שההורה בחר, באמצעות האפליקציה."
          }
        },
        secondChoice: {
          step1: {
            title: "התלמיד מוריד את האפליקציה",
            description:
              "מורידים את האפליקציה, נרשמים ומזינים פרטי תשלום (אשראי).",
            additionalNotes: {
              question: "לא יודעים איזה לוקר לבחור?",
              answer: "תוכלו לתת לתלמיד לבחור את הלוקר באפליקציה"
            }
          },
          step2: {
            title: "בוחרים לוקר ומשלמים",
            description:
              "בוחרים את הלוקר שרוצים, בוחרים מסלול תשלום (לתקופה או לפי שימוש) ומשלמים."
          },
          step3: {
            title: "פותחים את הלוקר באפליקציה",
            description:
              "פותחים את הלוקר שבחרתם באמצעות האפליקציה."
          }
        }
      }
    }
  },
  faq: {
    title: "שאלות ותשובות",
    subtitle: "משהו לא ברור? הנה מספר שאלות נפוצות שעלו בקרב לקוחותינו",
    items: {
      1: {
        title: "מי משכיר את הלוקרים?",
        description:
          "את הלוקרים מספקת חברת לוקרים מקבוצת רב בריח. החברה עוסקת בתחום השכרת ומכירת לוקרים לבתי ספר ומוסדות שונים בכל רחבי הארץ."
      },
      2: {
        title: "איך משכירים את הלוקרים?",
        description: "את הלוקר ניתן להזמין ולבחור באתר https://rb.lockerim.co.il או באמצעות אפליקציית LockerimRB."
      },
      3: {
        title: "הזמנתי לילד שלי לוקר באתר, איך הוא פותח את הלוקר?",
        description: "בסיום ההזמנה התלמיד יקבל סמס עם פרטי הלוקר שהזמנת וקישור לחנות האפליקציות להוריד את אפליקציית LockerimRB. לאחר התקנת האפליקציה בנייד של התלמיד, הוא יתחבר באמצעות מס' הטלפון הנייד שרשמתם בהזמנה. לאחר התחברות מוצלחת התלמיד יראה את פרטי הלוקר ויוכל להתחבר ולפתוח את המנעול החכם על ידי לחיצה על כפתור המנעול ופתיחה באפליקציה."
      },
      4: {
        title: "הזמנתי לילד שלי לוקר באתר והוא לא קיבל סמס",
        description: "יכול להיות שהטלפון של התלמיד חסום לקבל סמס ממספרים לא מוכרים? בכל מקרה, גם אם לא קיבל סמס התלמיד יוכל להוריד את אפליקציית LockerimRB מהחנויות ולהתחבר באמצעות הטלפון הנייד שרשמתם בהזמנה. לאחר התחברות מוצלחת התלמיד יראה מסך של פתיחת לוקר."
      },
      5: {
        title: "הזמנתי לוקר ואני לא יודע את הפרטים שלו",
        description: `את פרטי הלוקר תוכלו לראות על ידי לחיצה על "המבורגר" בפינה השמאלית העליונה של המסך ולחיצה על "ההזמנות שלי" בתפריט הצידי, שם יראה את פרטי הההזמנה ואת מיקום הלוקר שהזמנתם. לחיצה על הזמנה תעביר למסך פתיחת הלוקר.`
      },
      6: {
        title:
          "רשמתי את המספר טלפון של ההורה ולא של התלמיד. איך מעדכנים?",
        description: "חשוב שהמספר טלפון של התלמיד הוא זה שיהיה רשום במערכת, עם המספר הזה הוא מתחבר ומזדהה באפליקציה. במידה ובטעות ההזמנה בוצעה עם מס' טלפון לא של התלמיד יש ליצור קשר עם מוקד השירות (וואטסאפ בתפריט צד) כדי שיעזרו לך לעדכן את מס' הטלפון של התלמיד במערכת."
      },
      7: {
        title: "הצלחתי להתחבר לאפ' אך אני לא מצליח להתחבר למנעול",
        description: "ראשית יש לוודא שבהתקנת אפליקציית LockerimRB אישרת את ההרשאות הבאות. במכשירי אנדרואיד:@linebreak@ -שירותי מיקום - לחיבור תקין למנעול החכם@linebreak@ -אתר מכשירים קרובים - בלוטוס - לחיבור תקין למנעול החכם@linebreak@ - לבצע ולנהל שיחות טלפון - למעבר לשירות לקוחות בוואטסאפ@linebreak@ - לצלם תמונות וסרטונים - לסריקת לוקר באפליקציה.@linebreak@ במכשירי אייפון:@linebreak@ -בלוטוס - לחיבור תקין למנעול החכם@linebreak@ - לצלם תמונות וסרטונים - לסריקת לוקר באפליקציה@linebreak@ לאחר מכן יש לגשת ללוקר וללחוץ על הכפתור במנעול - המנעול יהבהב בכחול בקצב מהיר.@linebreak@ במקביל יש להחליק את הסלייד במסך פתיחת לוקר באפליקציה - בשלב זה הנייד יתחבר למנעול באמצעות תקשורת בלוטוס ולאחר התחברות מוצלחת המנעול יפתח ויצפצף ויהבהב פעמיים בצבע ירוק."
      },
      8: {
        title: "התחברתי למנעול, לחצתי על פתיחה. מה עליי לעשות כדי לפתוח את הלוקר?",
        description: "כדי לפתוח את הלוקר יש למשוך את המנעול החוצה מהוו. המנעול ימשיך להבהב בצבע כחול בקצב איטי - עד לכיבוי המנעול."
      },
      9: {
        title: "איך נועלים את הלוקר?",
        description: "את הלוקר ניתן לנעול באמצעות האפליקצייה או באמצעות לחיצה ממושכת על הכפתור שעל המנעול החכם."
      },
      10: {
        title: "מה קורה אם נגמרה לי הסוללה של הטלפון?",
        description: "בשלב זה תצטרך ליצור קשר עם מוקד השירות (וואטסאפ בתפריט צד) כדי שיעזרו לך עם קבלת קוד למכשיר נייד של חבר."
      },
      11: {
        title: "לא הגעתי לבית הספר. איך חבר שלי יכול להתחבר ולפתוח את הלוקר כדי להביא לי ציוד?",
        description: "ניתן להתחבר ללוקר שלך מכל נייד אחר. החבר יתחבר לאפליקציה עם מס' הטלפון שלך ואתה תצטרך לשלוח לו את הקוד שקיבלת בסמס. כאשר החבר יפתח את הלוקר הוא יקבל התראה שהאפ' מזהה שמנסים להתחבר למנעול עם נייד אחר. יש לאשר את ההתראה ולפתוח את הלוקר.@linebreak@ @underline@שים לב, לבקש מהחבר לבצע התנתקות באפליקציה לאחר שהוא מוציא את הציוד ונועל את הלוקר.@underline@"
      },
      12: {
        title: "נעלתי בטעות את הנייד שלי בתוך הלוקר. מה עושים?",
        description: "תוכל לפתוח את הלוקר עם נייד של חבר. בשלב זה תצטרך ליצור קשר עם מוקד השירות (וואטסאפ בתפריט צד) כדי שיעזרו לך עם קבלת קוד בהזדהות לאפליקציה עם הנייד של החבר."
      },
      13: {
        title: "אין קליטה טובה של אינטרנט בבית הספר. איך אפתח את הלוקר?",
        description: "המנעול החכם יכול להתחבר לאפליקציה גם אם אתה ללא קליטת אינטרנט.@linebreak@ לשם כך כל מה שצריך לאשר הרשאות הבאות באפליקציית LockerimRB במכשירי אנדרואיד:@linebreak@ - שירותי מיקום - לחיבור תקין למנעול החכם@linebreak@ - אתר מכשירים קרובים - בלוטוס - לחיבור תקין למנעול החכם@linebreak@ - לבצע ולנהל שיחות טלפון - למעבר לשירות לקוחות בוואטסאפ@linebreak@ במכשירי אייפון:@linebreak@ -בלוטוס - לחיבור תקין למנעול החכם@linebreak@ שים לב, יש להתחבר לאפ' במקום עם קליטת אינטרנט על מנת שתקבל את הקוד לאימות.@linebreak@ לאחר התחברות מוצלחת, תוכל לפתוח את הלוקר גם במקומות ללא אינטרנט בכלל."
      },
      14: {
        title: "מה קורה אם אני לא מצליח להתחבר לאפליקציה?",
        description: "אנא וודא שאתה נמצא במקום עם גישה לאינטרנט. לאחר מכן, שלח בקשת התחברות ותקבל SMS עם קוד התחברות. שים לב, לאחר 5 ניסיונות של הקלדת קוד שגוי, יחסם החשבון למשך 15 דקות על מנת להגן את ביטחונך."
      },
      15: {
        title: "מה קורה אם האפליקציה לא מתחברת למנעול?",
        description: "אנא וודא כי אישרת את כל ההרשאות לאפליקציה. ושירותי הבלוטוס ושירותי המיקום שלך פעילים ואז נסה שוב להתחבר למנעול."
      },
      16: {
        title: "החלפתי טלפון, האם המנעול יפתח כרגיל?",
        description: "יש להוריד את האפליקציה מחדש והמנעול יפתח ללא בעיה."
      },
      17: {
        title: "החלפתי מס' טלפון, מה עלי לעשות?",
        description: "צור קשר עם מוקד השירות (וואטסאפ בתפריט צד) כדי שיעזרו לך לעדכן את המספר החדש."
      },
      18: {
        title: "מה זה דמי פתיחה?",
        description: "דמי פתיחה הם תשלום חד פעמי המחויב בכל הזמנה של לוקר."
      },
      19: {
        title: "מתי מפנים את הלוקר ואיך עושים את זה?",
        description: "במידה ושכרת לוקר לתקופה קצובה - עליך לפנות את הלוקר בסוף התקופה, לסגור את הדלת של הלוקר ולהשאיר את המנעול נעול על גבי הלוקר. במידה ושכרת לוקר לפי שימוש - בעת סיום השימוש, פנה את הלוקר, סגור את הדלת של הלוקר, השאר את המנעול נעול על גבי הלוקר ולחץ על כפתור סיום השימוש במסך ההזמנות שלי באפליקציה."
      },
      20: {
        title: "לחצתי על נעילה והופיע באפ' נעילה תקולה, מה זה אומר?",
        description: "יש לוודא שהוו של הלוקר או חפץ אחר חוסמים את גולות הנעילה של המנעול. יש לפתוח את המנעול ולנסות לנעול שוב."
      },
      21: {
        title: "קפצה לי התראה של סוללה נמוכה, מה עושים?",
        description: "יש לפנות למוקד על מנת שיטפלו בהחלפת הסוללה."
      },
    }
  },
  landingPage: {
    title: "הזמנת לוקר",
    question: "איך לבחור את הלוקר המושלם עבור הילד שלך?",
    additionalText: "ממלאים את הפרטים ועונים על השאלות ויחד נמצא את הלוקר המתאים ביותר עבור הילד שלך!",
    buttonText: "בואו נתחיל!"
  },
  packageDeliveryConfirmation: {
    title: "הרשאה למסירת חבילה",
    content: {
      section_1: "אנא אשר לשליח למסור עבורך חבילה בלוקר.",
      section_2: "לאחר אחסון החבילה בלוקר, תקבל הודעה שהחבילה ממתינה לאיסוף.",
      section_3: "השימוש בלוקר הינו בהתאם לתנאי השימוש באפליקציה",
    },
    confirmationButton: "מאשר את מסירת החבילה",
    packageDeliveryConfirmationSuccessModal:{
      title: 'האישור התקבל בהצלחה',
      body: 'קיבלנו את אישורך למסירת חבילה בלוקר. תוקף האישור הוא 24 שעות.',
      confirmButton: 'הבנתי'
    }
  },
  chooseGrade: {
    title: "באיזה כיתה לומד התלמיד/ה?",
    gradeAndClass: "שכבה וכיתה",
    chooseGradeAndClass: "בחירת שכבה וכיתה"
  },
  chooseCompound: {
    title: "בחירת לוקר ב{siteName}",
    additionalTitle: "שם המתחם אינו ידוע?",
    chooseCompound: "בחירת מתחם",
    typeCompoundName: "הקלידו את שם המתחם או בחרו מהרשימה",
    compound: "מתחם",
    availableCellsCount: "({availableCellsCount} לוקרים פנויים)"
  },
  closetCarousel: {
    cellUnavailableErrorMessage: "הלוקר שחיפשת תפוס, יש לבחור אחד אחר",
    closetNumber: "ארון מס'",
    chooseCompoundForLoadLockersMessage: "עליך לבחור מתחם על מנת שנציג את הלוקרים הזמינים",
    lockerAvailable: "לוקר פנוי",
    lockerOccupied: "לוקר תפוס",
    lockerChoosen: "הבחירה שלך"
  },
  studentDetails: {
    title: "פרטי התלמיד",
    subTitle: "חשוב לשים לב! יש למלא את פרטי התלמיד על מנת שיוכל להתחבר לאפליקציית הלוקרים באמצעות הנייד האישי שלו",
    waitingLine: " - רשימת המתנה",
    form: {
      firstName: {
        label: "שמו הפרטי של בעל הלוקר",
        placeholder: "שם פרטי של התלמיד"
      },
      lastName: {
        label: "שם המשפחה",
        placeholder: "שם משפחה של התלמיד"
      },
      phoneNumber: {
        label: "מספר נייד של בעל הלוקר לשליחת קישור ללוקר",
        placeholder: "מספר הנייד של התלמיד לשליחת קישור לפתיחת הלוקר"
      },
      email: {
        label: "כתובת אימייל לשליחת החשבונית",
        placeholder: "מה המייל לצורך שליחת החשבונית?"
      },
      identificationNumber: {
        label: "מספר תעודת זהות",
        placeholder: "הקלידו מס׳ תעודת זהות כולל ספרת ביקורת"
      },
      agreementPolicy: {
        IAmAgreeTo: "אני מסכימ/ה ל",
        termsOfUse: {
          label: "תנאי השימוש",
          link: "https://lockerim.co.il",
        },
        andHaveUnderstoodThe: " והבנתי את ",
        privacyPolicy: {
          label: "מדיניות הפרטיות",
          link: "https://lockerim.co.il",
        },
        and: " ו",
        cookiesPolicy: {
          label: "מדיניות הקוקיז",
          link: "https://lockerim.co.il",
        },
        ofTheCompany: " של החברה",
      },
      joinMailingList: {
        label: "אני מסכימ/ה לקבל דיוור ישיר, הודעות ופרסומים שיווקיים באמצעות דוא\"ל, מסרונים (סמס) ובכל אמצעי תקשורת אלקטרוני אחר מהחברה ומקבוצת רב בריח",
      },
      grade: {
        label: "שכבה וכיתה",
        placeholder: "בחירת שכבה וכיתה"
      }
    }
  },
  chooseSubscription: {
    title: "בחירת מסלול",
    subTitle: "הלוקר שבחרת:",
    subscriptionName: "מסלול {marketingName}",
    choosenLockerText: "לוקר בגודל {cellSizeName} מספר {closetNumber}-{cellPosition}",
    choosenLockerLocation: "{siteName}, מתחם {CompoundName} - {compoundDescription}, {cityName}",
    subscriptionTitle: "מסלול {marketingName}",
    subscriptionInitialPriceText: "₪{price} עבור הפקת קוד",
    subscriptionPricingPeriodNameAndPrice: "₪{price} {pricingPeriodName}",
    subscriptionTotalPrice: `סה"כ: ₪{price}`,
    pricingPeriod_3: "למשך שנה",
    pricingPeriod_4: "למשך שנת לימודים"
  },
  orderSummary: {
    title: "סיכום הזמנה",
    lockerOwnerDetailsTitle: "פרטי בעל הלוקר",
    paymentSummaryDetailsTitle: `סה"כ לתשלום`,
    fullNameLabel: "שם מלא",
    schoolNameLabel: "בית הספר",
    phoneNumberLabel: "טלפון",
    lockerLabel: "לוקר",
    pricingPlanLabel: "מסלול",
    priceTotalLabel: `סה"כ לתשלום: ₪{price}`,
    continuePayment: "אישור הזמנה ותשלום",
    choosenLockerText: "לוקר בגודל {cellSizeName} מספר {closetNumber}-{cellPosition}",
    choosenLockerLocation: "{siteName}, מתחם {CompoundName} - {compoundDescription}, {cityName}",
    changeLockerSelectionText: "שינוי בחירה",
    subscriptionInitialPriceText: "₪{price} עבור הפקת קוד",
    subscriptionPricingPeriodNameAndPrice: "₪{price} {pricingPeriodName}",
    unAvailableCellPopup: {
      title: "אנחנו מתנצלים אבל הלוקר שבחרת כבר נתפס!",
      subTitle: "ניתן לבחור לוקר פנוי אחר",
      buttonText: "בחירת לוקר חדש"
    }
  },
  orderConfirmation: {
    title: "סיימנו, תתחדשו!",
    goHomeText: "מעבר לדף הבית",
    orderDetails: {
      title: "פרטי העסקה",
      orderNumber: "מספר עסקה",
      orderDate: "תאריך הזמנה",
      totalPayed: `סה"כ שולם`,
      emailForSendingInvoice: "אימייל לשליחת חשבונית",
      summaryOrderSentEmail: "סיכום הזמנה נשלח למייל",
      invoiceWillSentInSeperateEmail: "חשבונית תשלח במייל נפרד."
    },
    lockerOwnerDetails: {
      title: "פרטי בעל הלוקר",
      fullName: "שם מלא",
      schoolName: "בית הספר",
      phoneNumber: "טלפון"
    },
    lockerDetails: {
      title: "פרטי הלוקר",
      lockerDetails: "לוקר בגודל {cellSizeName} מספר {closetNumber}-{cellPosition}",
      lockerLocation: "{siteName}, מתחם {compoundName}, {cityName}",
      usingDuration: "משך שימוש",
      linkSentInstructionApp1: "שלחנו לנייד של {firstName} קישור להורדת",
      linkSentInstructionApp2: "האפליקציה עם גישה לפתיחת הלוקר.",
    },
    waitingListTitle: "אישור הזמנה - רשימת המתנה",
    studentDetails: {
      title: "פרטי התלמיד",
      fullName: "שם מלא",
      school: "בית הספר",
      identificationNumber: "תעודת זהות",
      phoneNumber: "טלפון",
      lockerNumber: "לוקר",
      compoundName: "מתחם",
      compoundNameWaitingList: "ברשימת המתנה למתחם",
      changeLocker: "שינוי בחירה"
    },
    parentDetails: {
      title: "פרטי המזמין",
      fullName: "שם מלא",
      phoneNumber: "טלפון",
      email: "דוא״ל"
    },
    paymentDetails: {
      title: "סה״כ לתשלום",
      period: "תקופה"
    },
    editDetails: "עריכת פרטים",
    button: "המשך לתשלום"
  },
  transactionIframe: {
    title: "פרטי תשלום",
    subTitle: "לתשומת ליבך! התשלום הינו עבור ההזמנה הנוכחית בלבד ופרטי האשראי לא ישמרו במערכת.",
    noChargePopup: {
      title: "לא התבצע חיוב",
      subTitle1: "לא התקבל אישור, אנא נסה שנית או הזן פרטי אשראי חדשים",
      subTitle2: "אנא נסה שנית או וודא שפרטי האשראי מעודכנים",
      buttonText: "חזרה לפרטי התשלום"
    }
  },
  payment: {
    title: "פרטי תשלום",
    totalPayment: "סך הכל לתשלום",
    form: {
      credit_card_number: {
        label: "מס׳ כרטיס",
        placeholder: "הקלידו מספר כרטיס"
      },
      expirationMonth: {
        label: "תוקף חודש",
        placeholder: "בחר חודש"
      },
      expirationYear: {
        label: "תוקף שנה",
        placeholder: "בחר שנה"
      },
      cvv: {
        label: "CVV",
        placeholder: "3 ספרות בגב הכרטיס"
      },
      card_holder_id_number: {
        label: "תעודת זהות של בעל הכרטיס",
        placeholder: "הקלידו מספר ת.ז"
      },
      amount: "סך הכל לתשלום"
    }
  },
  button: {
    continue: "המשך",
    choose: "בחירה",
    save: "שמירה",
    cancel: "ביטול",
    close: "סגירה",
    chooseAnotherLocker: "בחירת לוקר חדש",
    orderLocker: "הזמנת לוקר",
    fullProccessInfo: "למידע על התהליך המלא",
    goToFaq: "לעמוד שאלות ותשובות",
    backToMainPage: "חזרה לדף הראשי",
    goToLandingPage:"חזרה לדף הבית"
  },
  form: {
    errors: {
      onlyNumbers: "יש להזין מספרים בלבד",
      required: "שדה חובה",
      identificationNumber: "מספר תעודת הזהות אינו תקין",
      phoneNumber: "מספר הטלפון הנייד אינו תקין",
      email: "הדוא״ל שהוזן אינו תקין"
    }
  },
  migration: {
    voucher: {
      title: "השלמת פרטי רישום",
      subTitle: "אנא הזינו את מספר השובר / עסקה של הזמנת הלוקר",
      voucherNumber: {
        label: "מספר השובר / עסקה",
        placeholder: "הזינו את מספר השובר / עסקה של הזמנת הלוקר"
      }
    },
    otp: {
      title: " הזינו את הקוד שקיבלתם בSMS  ",
      timer: "קוד האימות ישלח תוך {timer} שניות",
      sendAgain: "שלח שוב"
    },
    studentDetails: {
      title: "פרטי תלמיד",
      subTitle:
        "אנא השלימו את הפרטים על מנת שהתלמיד/ה יוכלו להוריד את האפליקציה ולפתוח את הלוקר בקלות",
      firstName: {
        label: "שם פרטי",
        placeholder: "מלאו שם פרטי"
      },
      lastName: {
        label: "שם משפחה",
        placeholder: "מלאו שם משפחה"
      },
      identificationNumber: {
        label: "תעודת זהות",
        placeholder: "מלאו תעודת זהות"
      },
      phoneNumber: {
        label: "טלפון נייד",
        placeholder: "מלאו טלפון נייד"
      },
      school: {
        label: "בית ספר",
        placeholder: "מלאו בית ספר"
      },
      grade: {
        label: "כיתה",
        placeholder: "מלאו כיתה"
      },
      gradeNumber: {
        label: "מספר כיתה",
        placeholder: "מלאו מספר כיתה"
      },
      success: {
        title: "סיימנו, תתחדשו",
        subTitle: "נתראה ביום הראשון ללימודים"
      }
    }
  },
  schoolDetails: {
    title: "בחירת בית ספר",
    dialog: {
      title: " אתר {siteName} ({siteId})   ב{cityName} סגור להזמנות",
      subTitle: {
        line1: "מעוניינים שנשלח לכם הודעה כאשר האתר יפתח להזמנות?",
        line2: "אנא השאירו פרטים",
      },
      successTitle:"פרטיך נשמרו בהצלחה"
    },
    form: {
      city: {
        label: "ישוב",
        placeholder: "בחירת ישוב"
      },
      site: {
        label: "בית ספר",
        placeholder: "בחירת בית ספר"
      }
    }
  },
  lockerMethod: {
    title: "איך נבחר את הלוקר?",
    byGrade: "חיפוש לפי כיתה ומספר כיתה",
    byCompound: "לפי מיפוי",
    byLockerNumber: "לפי מספר לוקר",
    byStudent: "התלמיד יבחר את הלוקר",
    byLockerNumberPlaceholder: "לדוגמא: 123456-101",
    byGradePlaceholder: "לדוגמא: יא 7",
    searchByLockerNumberFormatErrorMessage: "מספר לוקר אינו בפורמט הנכון",
    locationLocker: "מיקום הלוקר:",
    locationLockerDescription: "{siteName}, מתחם {CompoundName} - {compoundDescription}, {cityName}",
    compound: "מתחם",
    lockerNumberNotFoundErrorMessage: "המערכת לא מזהה את מספר הלוקר, אנא בדוק שוב את הפרטים",
    lockerNumberUnavailableErrorMessage: "לוקר מס' {cellPosition} אינו פנוי. ניתן לבחור לוקר פנוי אחר במתחם {compoundName}"
  },
  ultimateErrorModal: {
    title: 'שגיאה כללית',
    confirm: 'אישור',
    errorCode: 'קוד שגיאה:',
    tranzila: {
      title: 'לא התבצע חיוב',
      subTitleGeneric: 'לא התקבל אישור, אנא נסה שנית או הזן פרטי אשראי חדשים',
      subTitleNoChargeWasMade: 'אנא נסה שנית או וודא שפרטי האשראי מעודכנים',
      buttonText: "חזרה לפרטי התשלום"
    },
    unAvailableCell: {
      title: "אנחנו מתנצלים אבל הלוקר שבחרת כבר נתפס!",
      subTitle: "ניתן לבחור לוקר פנוי אחר",
      buttonText: "בחירת לוקר חדש"
    },
    noAvailableCellsFound: {
      title: "לא נמצאו לוקרים פנויים",
      subTitle: "לא נותרו לוקרים פנויים, אנא בחר מתחם קרוב אחר",
      buttonText: "בחירת מתחם חדש"
    },
    siteIsNotOpenForOrders: {
      title: "אתר סגור להזמנות",
      subTitle: "מתנצלים, האתר עדיין לא פתוח להזמנות. אנא נסה במועד מאוחר יותר",
      buttonText: "בחירת אתר חדש"
    }
  },
  footer: {
    forDownloadingAppClick: "לחצו להורדת האפליקציה והתחברו עם מס' הטלפון שבצעתם עבורו את ההזמנה",
    supportTitle: "מוקד שירות לקוחות",
    supportPhoneNumber: "1-599-54-54-50"
  },
  termsOfUse: {
    title: "תנאי שימוש באתר",
    section_1: {
      title: "כללי",
      term_1: "ברוכים הבאים לאתר האינטרנט https://www.lockerim.co.il והאפליקציה \"LockerimRB\" (להלן: \"האפליקציה\") (לעניין תנאי שימוש אלה, להלן ביחד: \"האתר\") המצוי בבעלות חברת לוקר אמבין (21) בע\\\"מ, ח\\\"פ 516402989 מרחוב האופה 31, אשקלון, 7878030 (להלן: \"החברה\").",
      link: "https://www.lockerim.co.il",
      term_2: "ההוראות שבמסמך תנאי השימוש, מדיניות הפרטיות ובמדיניות ה-cookies (להלן ביחד: \"תנאי השימוש\") יחולו על שימוש באתר, בשירותים, במוצרים, בדפי הנחיתה, בפרסומות ובבאנרים ובכל ערוץ תקשורת נוסף ואחר של החברה וכל מי מטעמה ויהוו את הבסיס המשפטי בכל עניין שבין המשתמש באתר (להלן: \"המשתמש\"), לבין החברה וכל מי מטעמה. לפיכך, המשתמש נדרש לקרוא את תנאי השימוש בעיון רב.",
      term_3: "שימוש המשתמש באתר מהווה את הסכמתו להוראות תנאי השימוש. ככל שהמשתמש אינו מסכים לאמור בתנאי השימוש, עליו להימנע מלבצע כל שימוש באתר.",
      term_4: "השימוש בחלק מהשירותים ו/או המוצרים המוצעים באתר, מחייב התקנת האפליקציה. לצורך רכישה ושימוש באותם שירותים ו/או מוצרים, יידרש המשתמש, להתקין את האפליקציה וכן, בהתאמה ליתן הסכמתו לתנאי השימוש, מדיניות הפרטיות ומדיניות הקוקיז הנלווים באפליקציה, בהם ניתן לצפות בקישור להלן: https://rb.lockerim.co.il/download-app-new. למען הסר ספק, רכישת השירותים ו/או המוצרים שהשימוש בהם מחייב את התקנת האפליקציה כאמור, תהווה את הסכמת המשתמש  להתקנת האפליקציה. יודגש, כי הזמנת חלק מהשירותים, תתאפשר אך ורק באמצעות האפליקציה. ",
      link2: "https://rb.lockerim.co.il/download-app-new",
      term_5: "החברה רשאית, בכל עת וללא הודעה מוקדמת, לשנות את תנאי השימוש ואלה יחולו על המשתמשים מיד עם פרסומם.",
      term_6: "החברה רשאית בכל עת ומבלי לתת על כך הודעה, על פי שיקול דעתה הבלעדי, להפסיק או להשהות את הפעלת האתר, באופן חלקי או כולל, או לתקן או לשנות את אופיו ותוכנו.",
      term_7: "תנאי השימוש באתר מיועדים לנשים וגברים כאחד ונוסחו בלשון זכר מטעמי נוחות בלבד.",
      term_8: "כותרות הפרקים להלן מובאות לשם נוחות והתמצאות המשתמש ולא תשמשנה בפרשנות תנאי השימוש.",
      term_9: "השימוש באתר מותר למטרות אישיות בלבד של המשתמש ואין לבצע בו כל שימוש וכל רכישה למטרות מסחריות.",
      term_10: "המשתמש מסכים כי רשומות המחשב של החברה בדבר הפעולות המתבצעות דרך האתר ובקשר עם השירותים יהוו ראיה לנכונותן.",
      term_11: "השימוש באתר ובתכנים שבו הינו \"כפי שהוא\" (\"AS IS\"), דהיינו, ללא כל אפשרות להתערבות או שינוי מצד משתמש כלשהו ולצרכים פרטיים בלבד. אסור בתכלית האיסור להשתמש באתר ו/או בתוכנו, באופן חלקי או כולל, למטרות מסחריות. אין להשתמש בתוכן באופן חלקי או כולל לשם העתקה, תיקון, שינוי, שעתוק, שידור, הצגה, פרסום, העברה, מכירה, או הפצה בכל דרך שהיא על ידי משתמש כלשהו, ללא הסכמתה המפורשת של החברה מראש ובכתב.",
      term_12: "הצילומים, התמונות, התכנים והסרטונים המוצגים באתר הם להמחשה בלבד ואינם מחייבים את החברה."
    },
    section_2: {
      title: "הזכות/כשרות לרכוש את השירותים",
      term_12: "משתמש אשר עומד בתנאים המצטברים המפורטים להלן רשאי לרכוש שירותים באתר:",
      term_12_1: "המשתמש הנו כשיר לבצע פעולות משפטיות מחייבות, בכלל זה המשתמש מצהיר כי הוא בן 18 שנה או מבוגר יותר.",
      term_12_2: "המשתמש הנו בעל כרטיס אשראי תקף שהונפק כדין ע\"י אחת מחברות כרטיסי האשראי הפעילות גם בישראל או קיבל את הסכמתו מרצון של בעל כרטיס אשראי כאמור, לעשות בו שימוש.",
      term_12_3: "המשתמש הוא בעל תא דואר אלקטרוני ברשת האינטרנט.",
      term_12_4: "למען הסר ספק, התנאים המפורטים בסעיפים 13.1-13.2 לעיל, רלוונטיים לעניין האפשרות לבצע את רכישת/ הזמנת השירותים ואין בהם כדי למנוע ממי שטרם מלאו לו 18 שנים, מעשות שימוש באפליקציה לצורך הפעלת המנעול החכם.",
      term_13: "החברה שומרת לעצמה את הזכות למנוע את הגישה לרכישת שירותים ו/או לבטל רכישה של שירותים על ידי משתמשים והכל בהתאם לשיקול דעתה הבלעדי לרבות אך לא רק משתמשים אשר התנהגותם אינה הולמת או שאינה לפי הוראות המפורטות בתנאי השימוש או משתמשים אשר מנסים לפגוע בניהולו התקין של האתר."
    },
    section_3: {
      title: "שירותים/ מוצרים באתר ורכישתם",
      term_14: "האתר מספק את האפשרות לרכוש את השירותים/ המוצרים הבאים:"
    },
    section_4: {
      title: "השכרת לוקרים בליווי מנעול מכני ו/או מנעול חכם:",
      term_15: "יחד עם הלוקר, המשתמש יקבל מנעול מכני ו/או מנעול חכם, לפי סוג ההזמנה שביצע והמתחם הרלוונטי בו ממוקם הלוקר והכל, כמפורט באתר החברה ובכפוף לשיקול דעתה הבלעדי של החברה:",
      term_16: "מנעול מכני - מנעול שפתיחתו מתאפשרת בצורה מכנית באמצעות קוד (להלן: \"המנעול המכני\"). המנעול המכני, יותקן על גבי הלוקר טרם מסירתו למשתמש. המשתמש יקבל את הקוד לפתיחת המנעול בהזמנת הלוקר לדוא\"ל שמסר המשתמש במעמד רכישת השירותים.",
      term_17: "מנעול חכם - מנעול שפתיחתו והשימוש בו מתאפשר באמצעות האפליקציה ולאחר התקנתה (להלן: \"המנעול החכם\"). בחלק מהמתחמים בהם ממוקמים הלוקרים של החברה, ניתן יהיה להזמין מנעול חכם רק לאחר הזמנת הלוקר בליווי מנעול מכני ובנוסף למנעול המכני (להלן: \"אפסייל\"). מנעול חכם הנלווה להזמנת לוקר, יימסר למשתמש על ידי החברה ו/או מי מטעמה עם תחילת תקופת השימוש כהגדרתה מטה. המנעול החכם יקושר באמצעות קוד מוצפן לטלפון הנייד שמסר המשתמש בעת ביצוע ההזמנה. במידה ורכישת המנעול החכם נעשתה בנוסף למנעול המכני, המנעול המכני יתקבל על גבי הלוקר שיועד לשימוש המשתמש ואילו המנעול החכם יימסר למשתמש במתחם הלוקרים הרלוונטי בו מצוי הלוקר אותו הזמין לשימוש או ישלח לבית המשתמש, לפי שיקול דעת החברה.",
      term_18: "(המנעול המכני והמנעול החכם יכונו יחד להלן: \"המנעול\"). הסבר על פתיחה ראשונית של המנעול ניתן למצוא באתר.",
    },
    section_5: {
      title: "תחזוקת הלוקרים והמנעולים",
      term_33: "בכל מקרה של תקלה, בירור או בעיה אחרת הקשורה בלוקר ו/או במנעול, יש לפנות לשירות הלקוחות וההזמנות של החברה בטלפון: 1599545450.  החברה תטפל בפניית המשתמש לא יאוחר מחלוף 3 ימים עסקים ממועד קבלת הפניה לא כולל, ימי שבתון ו/או חג ו/או ימים בהם מוסד הלימוד לא פועל במקרה בו הלוקר ו/או המנעול ממוקמים במוסד לימוד."
    },
    section_6: {
      title: "השכרת או רכישת מנעול חכם בלבד (שלא בליווי הזמנת לוקר).",
      term_33: "החברה תאפשר למשתמש העומד בתנאי השימוש לרכוש את השירותים/ המוצרים באתר. כמו כן, החברה שומרת לעצמה את הזכות למנוע את הגישה לרכישת השירותים ו/או לבטל רכישות של משתמש שהתנהגותו אינה הולמת או שאינה לפי הוראות תנאי השימוש או המנסה לפגוע בניהולו התקין של האתר ו/או החברה. בנוסף, החברה שומרת לעצמה את הזכות להגביל את כמות השירותים שהמשתמש רשאי לרכוש באתר."
    },
    section_7: {
      title: "תקופת השימוש",
      term_34: "תקופת השימוש והשכרת הלוקרים בליווי מנעול מכני ו/או מנעול החכם או השכרת מנעול חכם לבדו, תחל במועד אישור העסקה על ידי החברה לאחר ביצוע ההזמנה ולפי בחירת המשתמש, יכול שתהא לאחת מתקופות השימוש כמפורט להלן לעיל ולהלן: \"תקופת השימוש\"):"
    },
    section_8: {
      title: "Pay per period",
      term_35: "בגדר חלופה זו, יוכל המשתמש להשכיר את הלוקר בליווי מנעול מכני ו/או מנעול חכם או להשכיר מנוי חכם לבדו (שלא בליווי השכרת לוקר), לתקופת שימוש קבועה מראש (להלן: \"שימוש לתקופה קבועה מראש\")  על פי אחת מהחלופות הבאות:",
      term_36: "בתי ספר יסודיים – עד ליום 30.6",
      term_37: "חטיבות ביניים ובתי ספר תיכוניים – עד ליום 20.6",
      term_38: "לתקופה קבועה כפי שסוכמה בין המשתמש לחברה, בכפוף לשיקול דעתה הבלעדי של החברה."
    },
    section_9: {
      title: "Pay per use",
      term_39: "בגדר חלופה זו, יוכל המשתמש להשכיר את הלוקר בליווי מנעול חכם לתקופה שאינה קבועה מראש (להלן: \"שימוש לתקופה שאינה קבועה מראש\").  שימוש לתקופה שאינה קבועה  מראש, יכול להיעשות רק בהשכרת לוקר בליווי מנעול חכם. לאחר חיבור ראשוני של המשתמש לשירות, יוכל המשתמש לעשות שימוש בלוקר ובמנעול החכם למשך פרק הזמן הדרוש לו ולשלם עם סיום השימוש. יובהר, כי השימוש לתקופה שאינה קבועה מראש, באופן רציף, מוגבל לתקופה בת 3 חודשים.",
      term_40: "מיד עם סיום תקופת השימוש, המשתמש יפנה את הלוקר מכל ציוד וישאיר את הלוקר נעול עם המנעול שנמסר למשתמש על ידי החברה.",
      term_41: "בתום סיום תקופת השימוש, החברה תהיה רשאית, אך לא חייבת, על חשבון של המשתמש, לפנות את הציוד שיושאר בלוקר ולאחסנו ו/או להעבירו לאב הבית של מוסד הלימוד או לגורם הרלוונטי במתחם בו ממוקם הלוקר והמנעול.",
      term_42: "המשתמש מוותר על כל טענה, דרישה ותביעה נגד החברה וכל מי מטעמה בכל הקשור והנוגע לציוד שהושאר על ידי המשתמש בלוקר לרבות אך לא רק פינוי הציוד על ידי החברה."
    },
    section_10: {
      title: "תשלום בגין השירותים/ המוצרים",
      term_43: "התשלום בגין השירותים ו/או המוצרים יהא בהתאם למחירים המופיעים באתר (להלן: \"התשלום\").",
      term_44: "בנוסף לתשלום כאמור בעד השירותים ו/או המוצרים, במועד רכישת השירותים, ישלם המשתמש לחברה את התשלומים הבאים, בהתאם לסוג העסקה שביצע, כמפורט להלן:"
    },
    section_11: {
      title: "דמי שימוש",
      term_45: "בהשכרת לוקר בליווי מנעול מכני, יגבה מהמשתמש סכום חד פעמי כמפורט באתר החברה (להלן: \"דמי השימוש\"). החברה תחזיר למשתמש ששילם את דמי השימוש לאחר סיום תקופת השירות בהתקיים התנאים המצטברים הבאים: (1) המשתמש החזיר לחברה מנעול תקין; (2) למשתמש יתרת זכות בגין דמי השימוש וטרם קיבל החזר בעדם (3) המשתמש הגיש בקשה להחזר דמי השימוש באמצעות הטופס המופיע באתר או אשר נשלח אל המשתמש באמצעות דואר אלקטרוני או מסרון; (4) הבקשה התקבלה אצל החברה בתוך 12 חודשים ממועד סיום קבלת השירותים. החזר של דמי השימוש יתבצע בהעברה בנקאית לחשבון בנק של המשתמש."
    },
    section_12: {
      title: "דמי פתיחה",
      term_46: "בהשכרת לוקר בליווי מנעול חכם, ישלם המשתמש דמי פתיחה בעד הקצאת מפתח דיגיטלי להפעלת המנעול החכם, אשר יוקצה עם ביצוע ההזמנה. סכום דמי הפתיחה יהא כמפורט באתר (להלן: \"דמי הפתיחה\")."
    },
    section_13: {
      title: "התשלום בגין רכישת השירותים/ המוצרים באתר יבוצע בהתאם לסוג השירות/ המוצר ומשך השימוש כמפורט להלן:",
    },
    section_14: {
      title: "שימוש לתקופה קבועה מראש:",
    },
    section_15: {
      title: "השכרת לוקר עם מנעול מכני ו/או מנעול חכם",
      term_47: "המשתמש ישלם עבור מלוא התקופה המוסכמת מראש וכן, עבור דמי השימוש או דמי הפתיחה (לפי העניין) והכל, באמצעות כרטיס אשראי או באמצעות פנייה ישירה לחברה בהתאם לפרטי ההתקשרות המפורטים בתנאי שימוש אלה."
    },
    section_16: {
      title: "השכרת מנעול חכם בלבד",
      term_48: "השכרת המנעול החכם לבדו, תיעשה לתקופה בת 12 חודשים, בגינה ישלם המשתמש את מלוא הסכום מראש באמצעות כרטיס אשראי כמפורט באתר במועד ביצוע ההזמנה. במעמד הזמנת המנעול החכם, נוסף על התשלום החודשי האמור, ישלם המשתמש עבור דמי משלוח כמפורט באתר במועד ההזמנה."
    },
    section_17: {
      title: "שימוש לתקופה שאינה קבועה מראש:"
    },
    section_18: {
      title: "השכרת לוקר עם מנעול חכם",
      term_49: "במעמד ביצוע ההזמנה, ישלם המשתמש דמי פתיחה בעד הקצאת המפתח הדיגיטאלי ויתרת התשלום עבור המשך השימוש בלוקר עם מנעול חכם תשולם בסוף תקופת השימוש ותחושב על בסיס שעתי /יומי לפי משך השירות, כמפורט באתר החברה. במקרה בו המשתמש סיים את השימוש לפני סיום שעה/יום מלאים, יחויב המשתמש בגין שעה שלמה/ יום שלם (לפי העניין) כמפורט בתעריף באתר. התשלום בגין דמי הפתיחה ובעד השכרת הלוקר והמנעול החכם, יתבצע באמצעות כרטיס אשראי או באמצעות פנייה ישירה לחברה בהתאם לפרטי ההתקשרות המפורטים בתנאי שימוש אלה. השכרת לוקר עם מנעול חכם לתקופה שאינה קבועה מראש יכולה להיעשות אך ורק באמצעות האפליקציה."
    },
    section_19: {
      title: "רכישת מנעול חכם:",
      term_50: "במעמד ביצוע הזמנת המוצר, ישלם המשתמש את התמורה בעד המוצר וכן, את עלות דמי המשלוח והכל, כמפורט באתר החברה. החברה תקנה למשתמש, אחריות כמפורט באתר החברה.",
      term_51: "בעת ביצוע ההזמנה, המשתמש ימסור לחברה את הפרטים הנדרשים לצורך ביצוע התשלום. המשתמש יקפיד למסור פרטים נכונים ומעודכנים.",
      term_52: "למען הסר ספק, יובהר, כי לפני אישור הזמנה המתבצעת באמצעות כרטיס אשראי, חברת הסליקה תבצע אימות פרטי כרטיס האשראי עימו בוצעה ההזמנה ותפנה לקבלת אישור על ביצוע התשלום מאת חברת האשראי. מבלי לגרוע מהוראות כל דין והוראות תנאי השימוש, החברה לא תאשר את ההזמנה ללא אישור חברת האשראי.",
      term_53: "במקרה בו לא אושרה העסקה ע\"י חברת האשראי ו/או בידי הבנק, יקבל המשתמש הודעה על כך שבקשתו לביצוע ההזמנה סורבה וכי ההזמנה לא הושלמה. לצורך השלמת הרכישה, המשתמש ייצור קשר עם שירות הלקוחות של החברה בכתובת: רחוב בת שבע 1, לוד או בטלפון: 1599545450 לצורך הסדרת התשלום, או לחלופין יבצע הזמנה חדשה עם כרטיס אשראי תקין.",
      term_54: "לאחר אישור ההזמנה, החברה תשלח למשתמש אישור בדבר ביצוע העסקה באמצעות הודעה שתישלח לנייד ו/או לדוא\"ל שמסר המשתמש במסגרת רכישת השירותים באתר.",
      term_55: "החברה רשאית, בהתאם לשיקול דעתה, לשנות מעת לעת את השירותים ואופן אספקת, להחליפם, לבטל אותם ועוד, והכל על פי שיקול דעתה הבלעדי.",
      term_56: "כמו כן, החברה רשאית, בהתאם לשיקול דעתה, לעדכן את מחירי השירותים באתר מעת לעת ובלא צורך בהודעה מוקדמת. המחיר התקף ביחס לרכישת שירות ו/או המוצר, הוא המחיר באתר בעת ביצוע רכישת השירותים ו/או המוצרים. אם עודכנו המחירים טרם השלמת ביצוע העסקה, יחויב המשתמש לפי המחירים המעודכנים."
    },
    section_20: {
      title: "אבדן מנעול או גרימת נזק למנעול",
      term_57: "מבלי לגרוע מהוראות תנאי השימוש, בהשכרת מנעול מכני או מנעול חכם (באופן נלווה להשכרת לוקר או באופן עצמאי), ככל שהמנעול אבד או הוחזר לחברה במצב שאינו תקין, יחולו ההוראות הבאות:",
      term_58: "מנעול מכני- המשתמש לא יהא זכאי לקבל החזר של דמי השימוש.",
      term_59: "מנעול חכם-  המשתמש ישלם לחברה סך של 200 ₪ כולל מע\"מ. ככל ונותרה למשתמש יתרת דמי שימוש בגין מנעול מכני (במקרה בו ביצע המשתמש אפסייל למנעול חכם והשיב את המנעול המכני לחברה כשהוא במצב תקין), הוא לא יהא זכאי לקבל החזר בגין דמי השימוש וכן, ישלם לחברה תשלום בסך של 175 ₪. משתמש אשר בוחר לנעול את הלוקר במנעול חכם, מצהיר כי ידוע לו והוא מסכים לכך שבמקרה של אבדן המנעול או במקרה שהמנעול הוחזר לחברה במצב שאינו תקין, המשתמש ישלם לחברה את סך ההחזר כמתואר בסעיף זה."
    },
    section_21: {
      title: "ביטול עסקה",
      term_60: "משתמש שרכש שירות באתר רשאי לבטל את העסקה בהתאם להוראות חוק הגנת הצרכן, התשמ\"א-1981 והתקנות מכוחו (להלן: \"חוק הגנת הצרכן\") אשר עיקריהן יובאו להלן:",
    },
    section_22: {
      title: "ביטול הזמנת השכרת לוקר בליווי מנעול מכני ו/או מנעול חכם או השכרת מנעול חכם לבדו לשימוש לתקופה קבועה מראש:",
      term_61: "המשתמש יהא רשאי לבטל את ההזמנה בתוך תקופה בת 14 ימים ממועד ביצוע ההזמנה או ממועד קבלת מסמך המפרט את פרטי ההזמנה (להלן: \"מסמך הגילוי\") (לפי המאוחר מביניהם) בין אם הוחל במתן השירות ובין אם לאו. הביטול יתבצע תוך שלושה ימי עסקים ממועד מסירת הודעת הביטול ובהודעת ביטול שנמסרה באמצעות דואר רשום, יתבצע הביטול תוך שישה ימי עסקים ממועד מסירת הודעת הביטול למשלוח (אלא אם נקב המשתמש במועד מאוחר יותר לביטול). במקרה בו בוטלה ההזמנה בתוך התקופה האמורה ובכפוף לאמור בסעיפים 19 ו-30 לתנאי שימוש אלה, תשיב החברה למשתמש, תוך 14 ימים ממועד קבלת הודעת הביטול, החזר בעד סך התמורה ששילם לחברה (לרבות בעבור דמי פתיחה ו/או דמי משלוח לפי העניין) בניכוי דמי ביטול בשיעור של 5% ממחיר מלוא התמורה ששולמה או בסך 100 ₪, לפי הנמוך מביניהם וכן, בניכוי החלק היחסי בעד השירות שניתן לו ובתוך כך, בין היתר, ניכוי מלוא דמי הפתיחה ככל שנגבו לפי העניין. מקום בו נגבו דמי שימוש, אלו יוחזרו למשתמש במלואם בכפוף לאמור בסעיף 30 והשבת המנעול כשהוא במצב תקין."
    },
    section_23: {
      title: "סיום עסקה להזמנת לוקר בליווי מנעול חכם לשימוש לתקופה שאינה קבועה מראש:",
      term_62: "המשתמש יהא רשאי לסיים את העסקה לתקופה שאינה קבועה מראש בכל עת ובמעמד סיום העסקה עקב השלמת השימוש, ישלם המשתמש אך בעד החלק היחסי של השירות שצרך לפי תקופת השימוש. למען הסר ספק, דמי הפתיחה אשר נגבו לצורך הקצאת המפתח הדיגיטאלי הינם חלק בלתי נפרד מהשימוש וצריכת השירות.",
      term_63: "המשתמש יוכל לבטל את ההזמנה ולסיים את תקופת השימוש באופן מידי באמצעות האפליקציה הייעודית. המשתמש רשאי להודיע על ביטול העסקה בכל אחת מהדרכים המנויות בסעיף 34 מטה, אך יודגש, כי בביטול שיעשה שלא באמצעות האפליקציה, הביטול יתבצע תוך שלושה ימי עסקים ממועד מסירת הודעת הביטול ובהודעת ביטול שנמסרה באמצעות דואר רשום, יתבצע הביטול תוך שישה ימי עסקים ממועד מסירת הודעת הביטול למשלוח (אלא אם נקב המשתמש במועד מאוחר יותר לביטול)."
    },
    section_24: {
      title: "ביטול רכישת מנעול חכם:",
      term_64: "המשתמש יהא רשאי לבטל את רכישת המנעול החכם ממועד ביצוע עסקת הרכישה ועד 14 ימים ממועד קבלת המנעול החכם או ממועד קבלת מסמך הגילוי, לפי המאוחר מביניהם. במקרה בו בוטלה הרכישה כאמור ובכפוף להשבת המנעול החכם לחברה כשאינו פגום ובלי שנעשה בו שימוש, תשיב החברה למשתמש את מלוא התמורה (לרבות, דמי המשלוח) ששילם תוך 14 ימים ממועד קבלת הודעת הביטול, בניכוי דמי ביטול בשיעור של 5% ממחיר התמורה ששולמה או בסך 100 ₪, לפי הנמוך מביניהם.",
      term_65: "ככל שהמשתמש הוא אדם עם מוגבלויות, אזרח ותיק או עולה חדש (כהגדרתם מונחים אלה בחוק), המשתמש רשאי לבטל את העסקה בתוך 4 חודשים ממועד עשיית העסקה, מיום קבלת המוצר (מנעול חכם)  או ממועד קבלת מסמך הגילוי, לפי העניין, לפי המאוחר מביניהם ובלבד, שההתקשרות בעסקה כללה שיחה בין החברה לבין המשתמש, לרבות, באמצעות תקשורת אלקטרונית. החברה תהיה רשאית לדרוש מהשתמש להציג תעודה מתאימה והכל בהתאם להוראות החוק.",
      term_66: "גביית דמי הביטול כמתואר דלעיל, תיעשה בכפוף לכך שהביטול לא נבע עקב פגם במוצר או עקב אי התאמה כהגדרתה להלן והכל, בהתאם ובכפוף לחוק הגנת הצרכן. לעניין זה, \"אי התאמה\" – אי התאמה בין השירות לבין הפרטים שנמסרו למשתמש בהתאם לחוק הגנת הצרכן; אי אספקת השירות במועד שנקבע לכך ו/או הפרה אחרת שבוצעה על ידי החברה.  במקרה של ביטול עקב פגם או אי התאמה, תשיב החברה למשתמש, תוך 14 ימים ממועד קבלת הודעת הביטול באחת הדרכים לביטול כמפורט בתנאי שימוש אלה, את התמורה ששולמה בידי המשתמש, תבטל את חיוב המשתמש ולא תגבה דמי ביטול כלשהם."
    },
    section_25: {
      title: "המשתמש ימסור לחברה את הודעת הביטול באחת מהדרכים הבאות:",
      term_67: "טלפון: 1599545450 ;",
      term_68: "דואר: רחוב האופה 31, אשקלון, 7878030;",
      term_69: "דוא\\\"ל: info@locker-ambin.co.il ;",
      term_70: "פקס: 08-9282184 ;",
      term_71: "באמצעות לינק ייעודי באתר",
      term_72: "באמצעות האפליקציה ביחס לשירותים המופעלים באמצעות האפליקציה בלבד.",
      term_73: "בהודעה כאמור ימסור המשתמש לחברה את הפרטים  כמפורט להלן: שם ומספר תעודת זהות. בביטול אותו מבצע המשתמש בהודעה בעל פה, יידרש לספק את מלוא הפרטים בסעיף זה, וכן, את מספר הזמנה."
    },
    section_26: {
      title: "השבת המנעול:",
      term_74: "ביטול עסקה שלא עקב אי התאמה/פגם- המשתמש מתחייב להחזיר לחברה את המנעול כשהוא נעול על גבי הלוקר. במקרה של ביטול עסקה להזמנת מנעול חכם בלבד או רכישת מנעול חכם בתוך התקופות המתוארות לעיל, יהא על המשתמש להשיב את המנעול החכם אל החברה בכתובתה כמפורט בסעיף 43 מטה.",
      term_75: "ביטול עסקה עקב אי התאמה/ פגם- בביטול כאמור במועדים על פי דין, המשתמש ישיב את המנעול לחברה במקום בו נמסר לו ויודיע על כך לחברה.",
      term_76: "החברה תמסור למשתמש עותק מהודעת ביטול החיוב כאמור."
    },
    section_27: {
      title: "עדכונים, מבצעים, הטבות והנחות",
      term_77: "החברה רשאית להציע באתר מעת לעת, מבצעים, הטבות, והנחות שונות והכל בהתאם לשיקול דעתה הבלעדי (להלן ביחד: \"הטבות\").",
      term_78: "אין למשתמש זכות קנויה ליהנות מהטבות. ככל שיחול שינוי בפרטי השירותים שהמשתמש רכש באתר או במחירם, החברה תהיה רשאית לבצע בדיקה מחודשת של זכאות המשתמש להטבה.",
      term_79: "במקרה של ביטול עסקה לרכישת שירותים שנרכשו במבצע או באמצעות שימוש בהטבה כלשהי, הסכום שיוחזר למשתמש, ככל שעומדת לו הזכות לקבלת החזר כספי, יהיה זה ששולם בפועל (כלומר לאחר מימוש המבצע או מימוש ההטבה) על ידי המשתמש."
    },
    section_28: {
      title: "משלוח עדכונים, הודעות, דברי פרסומת ומידע שיווקי",
      term_80: "החברה וכל מי מטעמה תהא רשאית לשלוח למשתמש, מעת לעת ובהתאם לשיקול דעתה הבלעדי, הודעות, תזכורות, עדכונים בנושאים שונים ועוד וזאת, לכתובת דוא\"ל, טלפון נייד וכל אמצעי אחר ונוסף.",
      term_81: "מבלי לגרוע מכלליות האמור, החברה וכל מי מטעמה עשויה לשלוח למשתמש מידע, הצעות ומבצעים על מוצרים ושירותים שונים של החברה ושל צדדים שלישיים לרבות אלה שעשויים לעניין את המשתמש ו/או להתאים להעדפות של המשתמש. הסכמת המשתמש לתנאי השימוש מהווה הסכמה כי המשתמש מסכים לקבל דברי פרסומת כהגדרת המונח בחוק התקשורת, התשמ\"ב-1982 בכל אמצעי אלקטרוני, לרבות הודעת דוא\"ל, הודעות, SMS, MMS ועוד."
    },
    section_29: {
      title: "קניין רוחני",
      term_82: "כל זכויות הקניין הרוחני באתר, לרבות הפטנטים, זכויות היוצרים, סימני המסחר, המדגמים, השיטות והסודות המסחריים, מידע באתר, תכנים ומידע שמועבר במסגרת ובקשר עם שירותים, במוצרים, תכנים ועוד הינם רכושה של החברה בלבד. זכויות אלה חלות, בין היתר, על עיצובו הגרפי של האתר, בסיסי הנתונים בו (לרבות הטקסטים, התמונות והסרטונים), קוד המחשב של האתר, כתובת האינטרנט שלו, קורסים, תכנים, מידע מכל מין וסוג וכל פרט אחר הקשור באתר ו/או השירותים.",
      term_83: "אין להעתיק, לשכפל, להפיץ, למכור, לשווק ולתרגם מידע כלשהו מן האתר (לרבות סימני מסחר, תמונות, טקסטים, סרטונים וקוד-מחשב) בלא קבלת רשותה המפורשת של החברה מראש ובכתב.",
      term_84: "אין לעשות כל שימוש מסחרי בנתונים המתפרסמים ע\"י האתר, בשירותים, בתכנים, ברשימות המופיעות באתר או בפרטים אחרים המתפרסמים בו בלא קבלת הסכמת החברה מראש ובכתב.",
      term_85: "אין להשתמש בנתונים כלשהם המתפרסמים באתר לצורך הצגתם באתר אינטרנט או בשירות אחר כלשהו, בלא לקבל את הסכמת החברה מראש ובכתב ובכפוף לתנאי אותה הסכמה (אם תינתן). בכלל זה נאסר לאסוף נתונים מן האתר באמצעות תוכנות ו/או להפיץ נתונים כאלה ברבים באופן מסחרי או במסגרת מסחרית.",
      term_86: "אין להציג את האתר בתוך מסגרת (Frame), גלויה או סמויה ואין לקשר לעמודים המצויים בתוכו (\"DEEP LINK\"), אלא לעמוד הבית בלבד.",
      term_87: "אין להציג את האתר בעיצוב או ממשק גרפי שונים מאלה שעוצבו לו, אלא בכפוף לקבלת הסכמת החברה לכך מראש ובכתב.",
      term_88: "שם המותג (לוקר אמבין ו/או לוקרים) ומותגים נוספים, סימני המסחר (בין אם נרשמו ובין אם לאו) - הם כולם רכושה של החברה בלבד. אין לעשות בהם שימוש בלא קבלת הסכמת חברה בכתב ומראש.",
      term_89: "אייקונים (icons) כל מידע ו/או תצוגה המופיעים באתר, לרבות גרפיקה, עיצוב, הצגה מילולית, סימני מסחר, סימני לוגו (logo) וכן עריכתם והצגתם של אלה, הנם בבעלות בלעדית של החברה. אין להעתיק לשכפל, להפיץ, לפרסם או להשתמש בכל דרך אחרת את התכנים המופיעים באתר אלא אם כן החברה נתנה את הסכמתה לכך מראש ובכתב.",
      term_90: "אין לעשות שום שימוש בשום סימן מסחרי או עיצוב של מוצר או דגם המופיעים באתר או בצילומים המצויים באתר שהינם קניין רוחני מוגן, הן מכח הדין בישראל והן מכח אמנות בינלאומיות אליהן הצטרפה מדינת ישראל."
    },
    section_30: {
      title: "שימושים אסורים",
      term_91: "הפעולות הבאות הינן אסורות והמשתמש איננו רשאי (ואיננו רשאי להתיר לכל צד שלישי) לבצע את הפעולות הבאות:",
      term_92: "להשתמש באתר ו/או בתוכן ו/או בשירותים לכל מטרה בלתי חוקית, בלתי מוסרית, בלתי-מורשית ו/או אסורה;",
      term_93: "להשתמש באתר ו/או בתוכן ו/או בשירותים למטרות שאינן פרטיות, ללא הסכמה מפורשת של החברה בכתב ומראש;",
      term_94: "להסיר או להפריד מהתוכן ו/או מהאתר כל הגבלות וסימנים המציינים זכויות קנייניות של החברה או נותני הרישיון שלה, לרבות כל ההודעות הקנייניות המופיעות בהם (כגון ©, TM או ®);",
      term_95: "להפר ו/או לפגוע בזכויות המשתמשים לפרטיות וזכויות אחרות, או לאסוף מידע אישי אודות משתמשים, בין אם באופן ידני או באמצעות שימוש בכל רובוט, עכביש, זחלן, כל יישום חיפוש או אחזור, או שימוש באמצעי, בתהליך או בשיטה ידניים או אוטומטיים אחרים על מנת להיכנס לאתר ולאחזר, לאסוף ו/או לשאוב מידע;",
      term_96: "לפגוע או לשבש פעולות האתר או השרתים או הרשתות המאחסנות את האתר,",
      term_97: "להפר הוראות כל דין בקשר עם השימוש באתר;",
      term_98: "לבצע כל פעולה היוצרת או העלולה ליצור עומס גדול על תשתית האתר;",
      term_99: "לעקוף את האמצעים בהם אנחנו משתמשים על מנת למנוע או להגביל את הגישה לאתר;",
      term_100: "להעתיק, לתקן, לשנות, להתאים, למסור, להנגיש, לתרגם, להפנות, לבצע הנדסה חוזרת, להמיר קוד בינארי לקוד פתוח, לעשות דה-קומפילציה, או להפריד כל חלק בתוכן או באתר, או להציג לציבור, ליצור יצירות נגזרות, לבצע, להפיץ, לתת רישיון משנה, לעשות כל שימוש מסחרי, למכור, להשכיר, להעביר, להלוות, לעבד, לאסוף, לשלב עם תוכנה אחרת – של כל חומר הכפוף לזכויות קנייניות של החברה, לרבות קניין רוחני של החברה;",
      term_101: "למכור, לתת רישיון, או לנצל למטרה מסחרית כלשהי כל שימוש או גישה לאתר;",
      term_102: "להעביר את התכנים בקשר ובמסגרת כל אחד מבין האתר והשירותים לצד שלישי כלשהו;",
      term_103: "להפר את תנאי השימוש וכל חלק מהם."
    },
    section_31: {
      title: "קישורים לאתרים",
      term_104: "האתר יכול שיציע קישורים, היפר-קישורים או באנרים לאתרים אחרים, אשר החברה אינה מפקחת עליהם ואינה בודקת אותם, את מהימנותם ואת חוקיותם ואת כל הקשור לתנאי השימוש שלהם, היבטי האבטחה שלהם ולמדיניות הגנת הפרטיות שלהם.",
      term_105: "החברה לא תהיה אחראית לשימוש שעושים משתמשים בקישורים המופיעים באתר, בדפים שאינם נמצאים באתר או באתרים אחרים, והמשתמשים מתחייבים לציית ולקיים את ההוראות והתנאים של אותם אתרים או דפי אינטרנט ולהתקשר ישירות לספקים של אתרים אלה או דפים אלה בכל הקשור לנושאים שיעלו בקשר עם כניסה, עיון, שימוש ועוד.",
      term_106: "החברה לא תישא בכל אחריות, מכל סוג שהיא, בקשר עם האתרים האחרים כאמור לרבות בכל הקשור לאיסוף, שימוש, העברה של מידע באתרים האחרים, התוכן שיפורסם בדפים של האתרים האחרים ועוד."
    },
    section_32: {
      title: "הגבלת אחריות",
      term_107: "המשתמש מתחייב כי ייעשה בלוקר ובמנעול שימוש סביר והולם בלבד ולמטרת אחסון ציוד המשתמש בלבד. המשתמש יישא באחריות מלאה בגין כל נזק שיגרם לחברה וכל מי מטעמה בקשר עם הלוקר, השירותים ועוד לרבות כל נזק שנגרם בקשר עם שימוש לא הולם ו/או סביר כאמור שייעשה בלוקר ו/או במנעול.",
      term_108: "החברה ו/או מי מטעמה לא יהיו אחראים ולא יישאו בכל נזק מכל מין וסוג שהוא, (לרבות אך לא רק נזקים ישירים, עקיפים, כלליים, לדוגמה, מיוחדים, עונשיים, מקריים ותוצאתיים) שיגרם למשתמש ו/או לצד שלישי, כתוצאה משימוש באתר ו/או בשירותים ו/או בלוקרים ו/או במנעולים - תהא עילת התביעה אשר תהא.",
      term_109: "החברה לא תישא בכל אחריות, מכל סוג שהיא, בקשר עם האתרים האחרים כאמור לרבות בכל הקשור לאיסוף, שימוש, העברה של מידע באתרים האחרים, התוכן שיפורסם בדפים של האתרים האחרים ועוד.",
      term_110: "האתר, המידע והתכנים הם מידע כללי אינפורמטיבי בלבד על בסיס \"AS-IS\". החברה אינה מצהירה ואינה מציגה ומבטיחה כי המידע והתכנים מתאימים לצרכי המשתמש ולמטרותיו.",
      term_111: "החברה עושה מאמצים כדי לוודא שהמידע המוצג באתר יהיה שלם ומדויק אך יובהר, כי עלולים להופיע בו, בתום לב, אי דיוקים, טעויות, או שגיאות אשר החברה לא תישא באחריות בקשר עם אי דיוקים או שגיאות כאמור.",
      term_112: "כל הסתמכות על המידע המוצג באתר הינה באחריותו המלאה של המשתמש והחברה לא תישא באחריות בגין ובקשר עם כל נזק שיגרם למשתמש כתוצאה מהסתמכות המשתמש על המידע ו/או למידת התאמת המידע לשימושו של המשתמש ו/או לצרכיו.",
      term_113: "החברה לא תהא אחראית לשימוש שהמשתמש עושה בשירותים לרבות כל שימוש שייעשה בלוקר בפועל, או לציוד המאוחסן בו, ולכל פעולה של המשתמש בהתבסס על המידע באתר ו/או במסגרת השירותים.",
      term_114: "החברה לא תישא בכל חבות, והיא פטורה מכל אחריות, בקשר עם כל נזק, הפסד או הוצאה, מכל מין וסוג שהם, (לרבות אך לא רק נזקים ישירים, עקיפים, כלליים, לדוגמה, מיוחדים, עונשיים, מקריים ותוצאתיים), אשר נגרמו ו/או ייגרמו למשתמשים ו/או לצד שלישי, בכל הקשור והנוגע לאיסוף המידע, השימוש בו, העברתו לצדדים שלישיים, שמירתו, אבטחתו ועוד.",
      term_115: "מבלי לגרוע מכלליות האמור, החברה לא תהא אחראית בגין כל אבדן ונזק, מכל סוג שהוא (לרבות אך לא רק נזקים ישירים, עקיפים, כלליים, לדוגמה, מיוחדים, עונשיים, מקריים ותוצאתיים) הקשורים, במישרין או בעקיפין, לאתר, לרשת האינטרנט, לתיבת דוא\"ל של המשתמש ולכל ציוד אחר של המשתמש לרבות אך לא רק בקשר עם, נזקים עקב הפרות אבטחה, וירוסים, באגים, הפרעות, תקלות, כשל במערכת, כשל טכני ועוד.",
      term_116: "החברה לא תהיה אחראית לכל איחור או עיכוב באספקה ו/או לאי-אספקה של השירות, שנגרמו כתוצאה מ\"כוח עליון\" ו/או מאירועים שאינם בשליטתה של החברה, מלחמות, שביתות, אסונות טבע, אירועים ביטחוניים, מגפות, סגרים, תקלות במערכת המחשוב, תקלות במערכות הטלפונים, תקלות בשירות הדואר האלקטרוני, שינויים במצב הביטחוני, בריאותי ומצבים ואירועים נוספים שאינם בשליטת החברה.",
      term_117: "היה ועל אף האמור בתנאי השימוש יקבע בית משפט כי לחברה אחריות כלפי המשתמש אזי אחריות של החברה בקשר עם תנאי שימוש אלה ועל פי דין, לרבות אך לא רק אחריות, הפסד, נזקים, סעדים עלויות והוצאות (לרבות הוצאות שכ\"ט סבירות) תוגבל לנזקים ישירים בלבד שיגרמו על ידי החברה וכל מי מטעמה בלבד וסך האחריות והחבות מצטברת של החברה וכל מי מטעמה לא תעלה סך התמורה אשר שולמה בפועל על ידי המשתמש עבור השירות ו/או המוצר שרכש מהחברה ובגינו הוגשה התביעה. מבלי לגרוע מהאמור לעיל, החברה לא תהא אחראית, לנזק עקיף, תוצאתי, מיוחד ו/או עונשי כלשהו."
    },
    section_33: {
      title: "שיפוי",
      term_118: "המשתמש מתחייב לשפות את החברה וכל מי מטעמה מפני כל נזק, אובדן, חבות, תביעה או דרישה ולרבות הוצאת משפטיות ושכר טרחת עו\"ד, שיגרם על ידי המשתמש ו/או צד שלישי כלשהו בעקבות שימוש המשתמש באתר ו/או בשירותים באתר שלא כדין ו/או הפרת תנאי מתנאי השימוש."
    },
    section_34: {
      title: "הדין החל וסמכות השיפוט",
      term_119: "התנאים וההוראות המפורטים בתנאי השימוש, וכן כל שינוי או תיקון שלהם, כמו גם השימוש באתר, יוסדרו על פי חוקי מדינת ישראל ללא התייחסות לסעיפי ברירת הדין החלים בה. סמכות השיפוט בקשר עם כל מחלוקת ו/או תביעה אשר יתעוררו בקשר עם השימוש באתר או הקשור אליו מסורה באופן בלעדי לבתי המשפט בתל אביב-יפו."
    },
    section_35: {
      title: "תנאים נוספים",
      term_120: "במידה והוראה מהוראות תנאי שימוש אלה, תקבע על ידי ביהמ\"ש כבלתי חוקית ו/או בלתי תקפה, למרות כוונת הצדדים, אזי לא יבטל הדבר את יתר הוראות תנאי שימוש אלה ו/או את חלקי אותה הוראה שבוטלה ו/או צומצמה על ידי הערכאה המשפטית.",
      term_121: "אין בתנאי שימוש אלה כדי לגרוע מכל זכות המוקנית לחברה לפי כל דין.",
      term_122: "ויתור, הימנעות מפעולה במועד או מתן ארכה לא ייחשב כוויתור של החברה על זכות מזכויותיה לפי תנאי שימוש אלה או על פי דין ולא ישמש מניעה לתביעה על ידה או מי מטעמה, אלא אם כן נעשה ויתור כזה באופן מפורש ובכתב.",
      term_123: "החברה רשאית, על פי שיקול דעתה הבלעדי, לשנות את תנאי השימוש מעת לעת וזאת ללא צורך במתן הודעה או התראה על כך. תנאי שימוש עדכניים שיפורסמו באתר יחייבו את המשתמש. המשך השימוש של המשתמש לאחר עדכון התנאים יהווה הסכמה של המשתמש לתנאים המעודכנים לרבות לשינויים."
    },
    section_36: {
      title: "יצירת קשר",
      mail: "info@locker-ambin.co.il",
      term_124: "ככל שלמשתמש ישנן שאלות בנוגע לתנאי השימוש, האתר והשירותים המשתמש מוזמן לפנות לחברה באמצעות דואר אלקטרוני בכתובת info@locker-ambin.co.il.",
    },
    section_37: {
      title: "מעודכן: חודש אוגוסט 2023.",
    },
  },
  privacyPolicy: {
    title: "מדיניות פרטיות",
    section_1: {
      term_1: "לוקר אמבין (21) בע\"מ ח\"פ 516402989 (להלן: \"החברה\") מרחוב האופה 31, אשקלון, 7878030 מכבדת את הפרטיות של המשתמשים באתר האינטרנט (להלן: ביחד: \"המשתמש\" או \"המשתמשים\") אותו היא מנהלת ומפעילה בכתובת https://www.lockerim.co.il (להלן: \"האתר\").",
      link: "https://www.lockerim.co.il"
    },
    section_2: {
      term_1: "מדיניות פרטיות זו ומדיניות ה-Cookies (להלן ביחד: \"מדיניות הפרטיות\") מסדירה את האופן שבו החברה עושה שימוש במידע אותו היא אוספת באתר ובקשר עם השירותים.",
    },
    section_3: {
      term_1: "המידע המובא להלן מיועד לסייע למשתמש להבין את מדיניות הגנת הפרטיות של החברה באתר. מדיניות הפרטיות כפי שתפורט להלן, תבהיר את המידע שהחברה אוספת אודות המשתמשים באתר, המטרות שלשמן החברה אוספת את המידע, איזה שימוש אפשר שהחברה תעשה במידע, מי הם הצדדים השלישיים שהחברה תעביר להם מידע וכיצד החברה מטפלת ושומרת את המידע.",
    },
    section_4: {
      term_1: "המידע שהמשתמש מוסר לחברה תלוי בהסכמתו וברצונו של המשתמש ואינה נובעת מחובה חוקית. המידע ישמר במאגר המידע של החברה לצורך המטרות המפורטות במדיניות פרטיות זו. השימוש באתר ו/או בשירותים המוצעים על ידי החברה במסגרת האתר, מעידים על הסכמת המשתמש למדיניות פרטיות זו וכי מידע יישמר וינוהל במאגר כאמור. השימוש במידע שבמאגר יעשה בהתאם להוראות מדיניות פרטיות זו או על פי הוראות כל דין, למטרות המפורטות להלן.",
    },
    section_5: {
      term_1: "מדיניות פרטיות זו כתובה בלשון זכר רק מטעמי נוחות ויש לראות בכתוב כמתייחס גם ללשון נקבה."
    },
    section_6: {
      title: "1.מהו מידע?",
      term_1: "במדיניות זו, משמעות המונח \"מידע\" הוא כל מידע הנאסף על ידי החברה או נמסר לה על ידי המשתמש או מי מטעמו, במסגרת השימוש של המשתמש באתר או בשירותים הכלולים בו, שבאמצעותו ניתן לזהות את המשתמש ומידע המוגן על פי חוק הגנת הפרטיות, התשמ\"א-1981 (להלן: \"החוק\")."
    },
    section_7: {
      title: "2.המקורות שבאמצעותם החברה אוספת מידע אודות המשתמש",
      term_1: "2.1. מידע שהמשתמש מוסר לחברה בין היתר בקשר עם רכישת השירותים;",
      term_2: "2.2. מידע שמתקבל אצל החברה בקשר עם השירותים שמעניקה החברה באתר;",
      term_3: "2.3. כאשר המשתמש מבקש להצטרף לרשימת התפוצה של החברה (ניוזלטר);",
      term_4: "2.4. באמצעות שימוש באתר.",
    },
    section_8: {
      title: "3.המידע אותו החברה אוספת אודות המשתמש",
      subTitle: "החברה תאסוף אודות המשתמשים את המידע שיפורט להלן (כולו או חלקו):",
      term_1: "3.1. שם מלא, מין, כיתה, בית ספר, פרטי התקשרות (טלפון, טלפון נייד, דוא\"ל), תאריך לידה, כתובת, שם וטלפון של ההורה.",
      term_2: "3.2. טוקן של פרטי כרטיס האשראי באמצעותו בוצעה הרכישה או מידע שנמסר על גבי שיק לרבות מספר חשבון בנק.",
      term_3: "3.3. פירוט והיסטוריית רכישות שביצע המשתמש באתר.",
      term_4: "3.4. העדפות צרכניות של המשתמש.",
      term_5: "3.5. כל מידע נוסף שהמשתמש מוסר ו/או מתקבל אצל החברה בקשר עם המשתמש ועם השימוש באתר, לרבות פניות של המשתמש לשירות הלקוחות של החברה.",
      term_6: "בנוסף, נציין כי פרטי כרטיס האשראי של המשתמש אשר רוכש באתר מקושרים ישירות לאתר של החברה הסולקת שבבעלות חברת טרנזילה בע\"מ בע\"מ (להלן: \"טרנזילה\"), לצורך ביצוע התשלום בגין השירותים. יובהר כי החברה איננה צד לכך. המשתמש אחראי לבדוק את מדיניות הפרטיות באתר של טרנזילה לפני ביצוע התשלום. כמו כן ומבלי לגרוע מהוראות מדיניות פרטיות זו, המשתמש מאשר ומסכים כי החברה לא תהא אחראית לכל נזק מכל סוג שהוא, עקיף או ישיר, שייגרם למשתמש ו/או למי מטעם המשתמש בכל הקשור לשמירה, אחסון ועיבוד המידע על ידי טרנזילה, לרבות אך לא רק במקרים בהם המידע יאבד, ייחשף או אם יעשה בו שימוש לא מורשה כלשהו."
    },
    section_9: {
      title: "4. מדוע החברה אוספת את המידע והשימוש שהחברה מבצעת במידע (\"המטרות\")?",
      subTitle: "החברה אוספת מידע אודות המשתמש לצורך המטרות הבאות – כולן או חלקן:",
      term_1: "4.1. לשם ובקשר עם מתן השירותים המסופקים על ידי החברה או מי מטעמה;",
      term_2: "4.2. הפעלה וניהול של האתר לרבות שיווק, פרסום, קידום מכירות ועידוד רכישות;",
      term_3: "4.3. על מנת לאפשר את ביצוע הפעולות באמצעות האתר על ידי המשתמש;",
      term_4: "4.4. לשם פיתוח ותפעולו של האתר;",
      term_5: "4.5. לשם תחזוקה של האתר וניטור פעילות המשתמש בו;",
      term_6: "4.6. לשם שיפור איכות השירותים שמספקת החברה;",
      term_7: "4.7. לשם יצירת קשר עם המשתמש;",
      term_8: "4.8. לשם עריכת נתונים סטטיסטיים והעברת מידע לרבות סטטיסטי או אחר, לצדדים שלישיים;",
      term_9: "4.9. לשם ניתוח, ומחקר ועריכת סטטיסטיקות;",
      term_10: "4.10. לשם עריכת סקרים בקרב המשתמשים;",
      term_11: "4.11. לשם הצגה והתאמת מודעות שיוצגו בפני המשתמשים לרבות אך לא רק ברשת האינטרנט ועשויים להתאים להעדפותיהם;",
      term_12: "4.12. ניתוח פרופילים של המשתמשים באתר לשם שיווק מוצרים לרבות אך לא רק ברשת האינטרנט;",
      term_13: "4.13. לצורך משלוח באמצעים אלקטרוניים דברי פרסומת, מידע על מוצרים ושירותים, הטבות, מבצעים, הנחות ומידע שיווקי;",
      term_14: "4.14. לצרכי דיוור ישיר כהגדרתו בחוק;",
      term_15: "4.15. לצרכים פנימיים של החברה;",
      term_16: "4.16. לשם התגוננות מפני תביעות, דרישות וטענות נגד החברה ומי מטעמה וכן נגד צדדים שלישיים;",
      term_17: "4.17. לשם אכיפה של הוראות תנאי השימוש ומדיניות פרטיות זו;",
      term_18: "4.18. לשם עמידה בהוראות כל דין."
    },
    section_10: {
      title: "5. העברה של המידע",
      term_1: "5.1. החברה לא תעביר ולא תמסור מידע לצדדים שלישיים אלא כמפורט במדיניות הגנת הפרטיות.",
      term_2: "5.2. העברת המידע לצדדים שלישיים תכלול מידע רלוונטי בלבד שאינו חורג מהמטרות שלשמן מועבר המידע ותתבצע באופן מידתי למטרה מוגדרת, מפורשת ולגיטימית.",
      term_3: "5.3. החברה רשאית להעביר את המידע או כל חלק ממנו לצדדים שלישיים בהתקיים אחד או יותר מהמקרים הבאים:",
      term_4: "5.3.1. צדדים שלישיים אשר מעניקים לחברה שירותים שונים ובין היתר שירותי תמיכה במערכות האבטחה ומערכות טכנולוגיית מידע (IT), שירותי אחסון מידע, שירותי סליקה, שירותי בניית אתרים, שירותי עריכת סקרים ומחקרים, חברות שליחויות ולוגיסטיקה, חברות אשר מעניקות שירותי פרסום ושיווק, עורכי דין או יועצים מקצועיים חיצוניים אחרים וכן צדדים שלישיים נוספים אשר מעניקים לחברה שירותים בקשר עם פעילותה.",
      term_5: "5.3.2. אם תתקבל אצל החברה הוראה לרבות צו שיפוטי המורה לה למסור את פרטי המשתמש או מידע אודות המשתמש בהתאם להוראות כל דין",
      term_6: "5.3.3. בכל מחלוקת, טענה, דרישה, תביעה או הליכים משפטיים כלשהם בין המשתמש או מי מטעמו לבין החברה או מי מטעמה.",
      term_7: "5.3.4. במקרה של העברה ו/או מכירה ו/או המחאה ו/או רכישה, של החברה ו/או נכסיה ו/או כל חלק מהם, בתמורה או שלא בתמורה, בין היתר במקרים אשר במסגרתם תועבר הבעלות באתר ו/או בתכניו, כולם או חלקם, לצדדים שלישיים, לרבות אך לא רק במקרה של מיזוג החברה ו/או פעילותה עם צדדים שלישיים, ולרבות מבלי לגרוע מכלליות האמור, במקרים של שינוי שליטה, מלא או חלקי, בחברה.",
      term_8: "5.3.5. אם תועלה טענה או יתעורר אצל החברה חשד כי משתמש ביצע מעשה ו/או מחדל אשר עשויים לפגוע בחברה, במי מטעמה, בצדדים שלישיים כלשהם לרבות משתמשים אחרים.",
      term_9: "5.3.6. אם תועלה טענה או יתעורר אצל החברה חשד כי משתמש עושה שימוש באתר לצורך ביצוע מעשה בלתי חוקי ו/או כדי לאפשר, להקל, לסייע ו/או לעודד את ביצועו של מעשה כזה.",
      term_10: "5.3.7. בכל מקרה שהחברה תסבור כי מסירת המידע נדרשת כדי למנוע נזק לחברה, למשתמש או לצד שלישי כלשהו.",
      term_11: "5.4. החברה רשאית להעביר ו/או לאחסן את המידע אצל צדדים שלישיים, כאמור לעיל, גם מחוץ למדינת ישראל ובכלל זה רשאית לאחסן את המידע אצל ספקי ענן אשר מאחסנים את המידע מחוץ לישראל לרבות במדינות שרמת ההגנה של המידע שונה מהוראות הדין הישראלי ואשר עשויות להעניק הגנה פחותה מזו הניתנת על פי דיני הגנת הפרטיות בישראל. מבלי לגרוע מהוראות מדיניות זו, שימוש באתר מעיד על הסכמת המשתמש למדיניות הגנת פרטיות זו לרבות להעברת מידע, שמירתו ואחסונו אצל צדדים שלישיים אשר ממוקמים מחוץ למדינת ישראל.",
      term_12: "5.5. מבלי לגרוע מהוראות מדיניות פרטיות זו, החברה וכל מי מטעמה לא תישא בכל חבות, והיא פטורה מכל אחריות, בקשר עם כל נזק, הפסד או הוצאה, מכל מין וסוג שהם, בין אם ישירים ובין אם עקיפים ו/או נסיבתיים ו/או תוצאתיים, אשר נגרמו ו/או ייגרמו למשתמשים ו/או לצד שלישי, בכל הקשור והנוגע לספקי שירות לרבות בכל הקשור לאיסוף המידע על ידי ספקי שירות, השימוש בו, העברתו לצדדים שלישיים, שמירתו ואבטחתו על ידי ספקי שירות ועוד. ",
    },
    section_11: {
      title: "6. אבטחת מידע",
      term_1: "החברה נוקטת באמצעים טכניים ופיזיים בעניין פרטיות ואבטחת מידע בהתאם לסטנדרטים מקובלים. כידוע, אחסונו והעברתו של מידע באמצעים אלקטרוניים, לרבות דרך רשת האינטרנט, לעולם לא יכול להיות בטוח לחלוטין ובכל עת שבה המשתמש מוסר מידע לחברה, בפרט באמצעות רשת האינטרנט, המשתמש עושה זאת בהסכמה מלאה ובכפוף לסכנות הכרוכות בהעברת המידע בדרך זו. החברה לא מתחייבת שהאתר יהיה חסין באופן מוחלט מפני גישה בלתי-מורשית למידע המאוחסן בו. בכל מקרה, אם יש למשתמש סיבה להאמין כי העברת המידע לחברה אינה בטוחה עוד, אנא עדכן אותנו באמצעי התקשרות שבסעיף 15 למדיניות הפרטיות."
    },
    section_12: {
      title: "7. הגבלת אחריות",
      term_1: "החברה וכל מי מטעמה לא תישא בכל חבות, והיא פטורה מכל אחריות, בקשר עם כל נזק, הפסד או הוצאה, מכל מין וסוג שהם, בין אם ישירים ובין אם עקיפים ו/או נסיבתיים ו/או תוצאתיים, אשר נגרמו ו/או ייגרמו למשתמשים ו/או לצד שלישי, בכל הקשור והנוגע לאיסוף המידע, השימוש בו, העברתו לצדדים שלישיים, שמירתו ואבטחתו."
    },
    section_13: {
      title: "8. תקופת החזקה של המידע",
      term_1: "החברה תשמור את המידע אודות המשתמש לפרק הזמן הדרוש להבטחת המטרות המפורטות במדיניות פרטיות זו אלא אם תקופת החזקה ארוכה יותר נדרשת או מותרת על-פי דין."
    },
    section_14: {
      title: "9. מידע על אנשים אחרים",
      term_1: "אם המשתמש יספק מידע אישי לחברה בקשר לאנשים אחרים לרבות בני משפחתו וצדדים שלישיים נוספים, על המשתמש לעשות אך ורק לאחר: (א) שהמשתמש עדכן את הצדדים השלישיים אודות תוכן מדיניות זו; וכן – (ב) המשתמש קיבל הסכמה הנדרשת לאיסוף, שימוש, חשיפה והעברה של מידע אישי אודות הצד השלישי בהתאם למדיניות זו ובהתאם להוראות הדין, ככל שנדרש. המשתמש מצהיר ומאשר כי הוא קיבל את ההסכמות הנדרשות להעברת המידע לידי החברה.      ככל שהמשתמש מוסר פרטים אודות צד שלישי כלשהו, המשתמש מתחייב להביא לידיעת אודות צד שלישי את כל הנתונים הנדרשים לגיבוש הסכמה של הצד השלישי לשימושים שתעשה החברה במידע אודות ולקבל את הסכמת המשתמש להוראות מדיניות פרטיות זו. ככל שהורה מוסר פרטים אודות ילדו, הרי שבמסירת הפרטים ההורה מצהיר ומאשר כי הוא האפוטרופוס הבלעדי של הילד וכי יש לו את כל הזכויות להעביר את הפרטים אודות הילד לידי החברה כדי שהחברה תאסוף, תעבד, תעביר ותעשה שימוש במידע בהתאם למדיניות פרטיות זו.   שהחברה תפעל ב את הפרטים והמידע אודות הילד כמפורט במדיניות פרטיות זו."
    },
    section_15: {
      title: "10. Cookies",
      term_1: "האתר עושה שימוש ב-Cookies וטכנולוגיות אחרות לשם אחת או יותר מהמטרות המפורטות במדיניות פרטיות זו. למידע נוסף אודות אופן השימוש ב-Cookies ובטכנולוגיות באתר ניתן לעיין במדיניות ה-Cookies המופיעה באתר."
    },
    section_16: {
      title: "11. זכות עיון ותיקון המידע",
      term_1: "הזכות לעיין ולתקן את המידע האישי היא בהתאם להוראות החוק. אם המשתמש מעוניין לעיין או לתקן את המידע, ניתן לעשות זאת באמצעות פנייה לחברה בהתאם לפרטי התקשרות המופיעים בסעיף 15 למדיניות פרטיות זו להלן."
    },
    section_17: {
      title: "12. הדין החל וסמכות השיפוט",
      term_1: "12.1. התנאים וההוראות המפורטים במדיניות הגנת הפרטיות זו ובמדיניות ה-Cookies, וכן כל שינוי או תיקון שלהם, כמו גם השימוש באתר, יוסדרו על פי חוקי מדינת ישראל ללא התייחסות לסעיפי ברירת הדין החלים בה.",
      term_2: "12.2. סמכות השיפוט בקשר עם כל מחלוקת ו/או תביעה אשר יתעוררו בקשר עם השימוש באתר ומדיניות פרטיות זו או הקשור אליו, מסורה באופן בלעדי לבתי המשפט בתל אביב-יפו."
    },
    section_18: {
      title: "13. שינויים ועדכונים",
      term_1: "החברה רשאית, על פי שיקול דעתה הבלעדי, לשנות את מדיניות הפרטיות מעת לעת וזאת ללא צורך במתן הודעה או התראה על כך. מדיניות הפרטיות העדכנית שתפורסם באתר תחייב את המשתמש. המשך השימוש של המשתמש באתר לאחר עדכון מדיניות הפרטיות יהווה הסכמה של המשתמש למדיניות הפרטיות המעודכנת לרבות לשינויים."
    },
    section_19: {
      title: "14. שונות",
      term_1: "14.1. המשתמש אינו רשאי להסב את זכויותיו ו/או חובותיו על פי מדיניות פרטיות זו לאחר. החברה תוכל להעביר את זכויותיה ו/או התחייבויותיה לאחר.",
      term_2: "14.2. אין במדיניות פרטיות זו כדי לגרוע מכל זכות המוקנית לחברה ו/או למי מטעמה לפי כל דין."
    },
    section_20: {
      title: "15. יצירת קשר",
      mail: "info@locker-ambin.co.il",
      term_1: "ככל שלמשתמש ישנן שאלות נוספות או הערות בנוגע למדיניות הפרטיות, המשתמש מוזמן לפנות לחברה באמצעות דואר אלקטרוני בכתובת info@locker-ambin.co.il."
    },
    section_cookies: {
      title: "מדיניות ה-Cookies",
      term_1: "1. לוקר אמבין (21) בע\"מ, ח\"פ 516402989 (להלן: \"החברה\") עושה שימוש ב-cookies ובטכנולוגיות אחרות (להלן ביחד: \"העוגיות\") באמצעותם החברה אוספת מידע לא מזהה בקשר עם משתמשים באתר.",
      term_2: "עוגיות הן קבצי טקסט קטנים המאפשרות לאסוף מידע דרך מחשבי משתמשים או דרך דפדפן האינטרנט ובין היתר, את סוגי המידע הבאים: הדפים בהם ביקר המשתמש באתר, פעולות שביצע משתמש באתר, משך הזמן ששהה משתמש באתר, מידע שמשתמש צפה באתר, מיקום גיאוגרפי משוער, טווח גילאים של משתמש, סוג המכשיר ממנו נכנס לאתר, תחומי עניין של משתמש, כתובת ה-IP של משתמש (קרי, כתובת המחשב של משתמש באינטרנט), מספר כניסות לאתר, מספר ההקלקות, רזולוציית מסך, סוג מערכת הפעלה וגרסתה, סוג דפדפן אינטרנט, גרסתו והעדפת שפה, שמות דומיינים ועוד.",
      term_3: "2. החברה תעשה שימוש במידע שנאסף באמצעות העוגיות לצורך אחת או יותר מהמטרות הבאות: כדי לנהל ולתפעל  את האתר; כדי להקל על השימוש באתר; כדי להציג למשתמש תכנים ופרסומים באתר וכן מחוץ לאתר (לדוג' ברחבי האינטרנט, ב- Google  ועוד) אשר עשויים לעניין אותו ו/או להתאים להעדפותיו; כדי לנתח, לחקור ולבצע בקרות בקשר עם האתר; כדי לנטר דפוסי פעילות באתר; כדי לערוך סטטיסטיקות לרבות חישוב מספרי משתמשים באתר; כדי לנתח, לחקור ולבצע בקרות בקשר עם האתר.",
      term_4: "3. החברה עושה שימוש בעוגיות של מערכות פרסום כגוןGoogle  אשר מנטרות את השימוש של המשתמשים באתר. השימוש במידע שייאסף באמצעות העוגיות שלGoogle  ושל כפוף לתנאי השימוש ומדיניות הפרטיות של Google כאמור וניתן ללמוד אודות המידע שנאסף באמצעות העוגיות, השימוש במידע על ידי החברות כאמור, אופן ביטול העוגיות ועוד בתנאי השימוש ובמדיניות הפרטיות של החברות האמורות.",
      term_5: "4. החברה עושה שימוש בשירותי אנליזה, ניתוח סטטיסטי ומחקר של צדדים שלישיים כגון Google Analytics. השימוש בשירותים שלGoogle Analytics  כפוף לתנאי השימוש ומדיניות הפרטיות של Google Analytics. ניתן ללמוד על המידע שנאסף במסגרת שירותים אלה, במדיניות הפרטיות של Google Analytics.",
      term_6: "5. משתמש אשר אינו מעוניין שהעוגיות יאספו במחשבו, יכול להימנע מכך על ידי שינוי ההגדרות בדפדפן במחשבו. לשם כך, על המשתמש להיוועץ בקובץ העזרה של הדפדפן. ביטול העוגיות עלול לגרום לכך שחלק מהשירותים באתר לא יהיו זמינים או שאיכותם תיפגע. על אף האמור לעיל, עוגיות אשר חיוניות והכרחיות לתפעול וניהול האתר, לא ניתנות לביטול.",
      term_7: "6. התנאים וההוראות המפורטים במדיניות זו וכן כל שינוי או תיקון שלהם יוסדרו על פי חוקי מדינת ישראל ללא התייחסות לסעיפי ברירת הדין החלים בה.",
      term_8: "7. סמכות השיפוט בקשר עם כל מחלוקת ו/או תביעה אשר יתעוררו בקשר עם מדיניות זו מסורה באופן בלעדי לבתי המשפט בתל אביב-יפו.",
      term_9: "8. החברה רשאית, על פי שיקול דעתה הבלעדי, לשנות מדיניות זו  מעת לעת וזאת ללא צורך במתן הודעה או התראה על כך. מדיניות ה-Cookies העדכנית שתפורסם באתר תחייב את המשתמש. המשך השימוש של המשתמש באתר לאחר עדכון מדיניות ה-Cookies יהווה הסכמה של המשתמש למדיניות המעודכנת לרבות לשינויים.",
    }
  }
};

export default translations;
